/**
 * Intenta ajustar el ancho de los skies (publicidad en los laterales de la página) estableciendo la clase
 * CSS que se ajusta al ancho del sky.
 *
 * @param {HTMLElement} elem Sky Ad-Holder  (ad-sky-(left|right)>.ad-holder
 * @return {number|null} Si consiguió ajustar el ancho, indica el ancho del banner. En caso de no reconocer el ancho, nulo.
 */
const _setFloatingClassInSky = (elem) => {
    const offset = elem.offsetWidth;
    if (!offset) {
        return false;
    }

    switch (elem.offsetWidth) {
        case 120:
        case 121:
            elem.classList.add('ad-holder-120');
            return 120;
        case 140:
        case 141:
        case 160:
        case 161:
            elem.classList.add('ad-holder-160');
            return 160;
        case 310:
        case 311:
            //Con esta medida solo puede ser el skin (formar una U invertida con la publi)
            // El skin tendrá al menos 1000px de alto y 310 px de ancho.
            elem.classList.add('ad-skin');
            return 310;
        default:
            return null;
    }
}


let skyWrapIsAlreadyFloating = false;
/**
 * A partir del formato publicitario recibido (formatId), determina si es el sky dcho. y/o izdo.
 * y el ancho de este (a través de la función anterior). Una vez determinado, hace el contenedor
 * sticky con la utilidad de JS.
 *
 * !! Si el ancho del elemento es 310 se entiende que es el skin (U inversa publicitaria). En este
 * caso, el offset del sticky es de 335px.
 *
 * @param {number} formatId Formato SAS para diferenciar si es el derecho o el izquierdo
 */
export const stickableSkies = (formatId) => {
    const width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    $(function () {
        if (width < 1004) return

        let elem;

        switch (formatId) {
            case 102580: //left
                elem = document.querySelector('.ad-sky-left>.ad-holder')
                break;
            case 102581:
                elem = document.querySelector('.ad-sky-right>.ad-holder')
                break;
            default:
                console.error('Error positioning skies. Unknown code ' + formatId)
                return;
        }

        let tries = 0, maxTries = 20;

        let intervalId = setInterval(() => {
            const positioning = _setFloatingClassInSky(elem)
            const isSkin = positioning === 310;

            if (positioning || (tries > maxTries)) {
                clearInterval(intervalId)

                //Make it sticky
                if (!skyWrapIsAlreadyFloating) {
                    skyWrapIsAlreadyFloating = true;
                    $('.ad-sky-wrap').each(function () {
                        const $elem = $(this);
                        const offset = isSkin ? 335 : 45;

                        const $parent = $(this).parent();
                        $elem.stick_in_parent({
                            'parent': $parent,
                            'offset_top': offset,
                            spacer: false
                        });
                    });
                }

                return
            }
            tries++;
        }, 300)

    })
}